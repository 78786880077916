import BuiltInIndia from '@components/atoms/built-in-india'
import { currentYear } from '@lib/utils/date.utils'
import { Trans } from '@lingui/macro'

export const CopyRight = (): JSX.Element => {
  return (
    <p className="text-xs my-3 font-500 text-grey-900">
      <Trans id="trialClass.copyRight">
        <span className="text-black font-700">©️</span> {currentYear} Codingal.{' '}
      </Trans>
      <BuiltInIndia />
    </p>
  )
}
