import { useState, useEffect } from 'react'
import {
  CODING_PROJECT_TESTIMONIALS,
  COUNTRY_CUSTOMER_TESTIMONIALS,
  MIDDLE_EAST_COUNTRY,
} from '@lib/data/testimonials'
import { CustomerTestimonialType } from '@lib/types/testimonials'
import { TESTIMONIAL_ENUM } from '@lib/constants/testimonials'
import { staticFile } from '@common/utils'
import { CopyRight } from '@components/common/copyright'
import { FastlyImage } from '@components/common/image'
import useDetectCountry from '@hooks/useDetectCountry'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import MsgTrans from '@components/common/i18n/message-trans'
import { msgT } from '@lib/utils/i18n'
import { Trans } from '@lingui/macro'

const IMAGE_PATH = '/images/landing-page/testimonials/'

type Props = {
  isCodingProject?: boolean
}
export const TestimonialCard = ({ isCodingProject }: Props): JSX.Element => {
  const [testimonial, setTestimonial] = useState<CustomerTestimonialType<
    LinguiJSMessageDescriptor
  > | null>(null)
  const { countryCode } = useDetectCountry()
  useEffect(() => {
    setTestimonial(() => {
      if (isCodingProject) {
        return CODING_PROJECT_TESTIMONIALS[
          Math.floor(Math.random() * CODING_PROJECT_TESTIMONIALS.length)
        ]
      }
      if (Object.keys(COUNTRY_CUSTOMER_TESTIMONIALS).includes(countryCode)) {
        return COUNTRY_CUSTOMER_TESTIMONIALS[countryCode][
          Math.floor(
            Math.random() * COUNTRY_CUSTOMER_TESTIMONIALS[countryCode].length
          )
        ]
      } else if (MIDDLE_EAST_COUNTRY.includes(countryCode)) {
        return COUNTRY_CUSTOMER_TESTIMONIALS['MIDDLE_EAST'][
          Math.floor(
            Math.random() * COUNTRY_CUSTOMER_TESTIMONIALS['MIDDLE_EAST'].length
          )
        ]
      } else {
        return COUNTRY_CUSTOMER_TESTIMONIALS['DEFAULT'][
          Math.floor(
            Math.random() * COUNTRY_CUSTOMER_TESTIMONIALS['DEFAULT'].length
          )
        ]
      }
    })
  }, [countryCode])

  return (
    <div className="md:pl-6">
      <div className="lg:max-w-sm rounded-md overflow-hidden bg-white border-2 border-grey-200 px-4 md:px-8">
        {testimonial && (
          <div className="flex flex-col py-4">
            <img
              src={staticFile('/images/landing-page/testimonials/quote.svg')}
              className="h-16 w-16"
              alt="5 stars"
            />
            <p className="font-600 text-grey text-2xl pb-8">
              <MsgTrans msg={testimonial.testimonial} />
            </p>

            <div className="flex">
              <FastlyImage
                src={`${IMAGE_PATH}${testimonial.image}.png`}
                webpSrc={`${IMAGE_PATH}${testimonial.image}.webp`}
                alt={msgT(testimonial.name)}
                width={127}
                height={144}
              />

              <div className="flex flex-col justify-center px-4 text-grey-900 text-xl">
                <div className="font-600">
                  <MsgTrans msg={testimonial.name} />
                </div>
                {testimonial.type === TESTIMONIAL_ENUM.PARENT && (
                  <div className="font-400">
                    <Trans>Parent</Trans>
                  </div>
                )}
                {testimonial.type == TESTIMONIAL_ENUM.STUDENT && (
                  <div className="font-400">
                    <Trans>
                      Student <span className="text-grey-700">|</span> Grade{' '}
                      {testimonial.grade}
                    </Trans>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="text-center">
        <CopyRight />
      </div>
    </div>
  )
}

export default TestimonialCard
