import { staticFile } from '@common/utils'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { CustomerTestimonialType } from '@lib/types/testimonials'
import { defineMessage } from '@lingui/macro'

export const CUSTOMER_TESTIMONIALS = [
  {
    text:
      'Codingal’s demo class helped me develop a keen interest in coding. So further, I decided to learn app and web development. Coding is now super fun for me, and it has improved my thinking and logical skills.',
    image: staticFile('/images/landing-page/testimonials/dikshita-karkare.jpg'),
    name: 'Dishita Karkare',
    info: 'Grade - 5 | Bangalore, India',
  },
  {
    text:
      'Learning to code with Codingal has been a great journey. I liked my teacher, and I learned a lot of new things that I could have never learned. Overall, Codingal is amazing if you want to start coding :)',
    image: staticFile('/images/landing-page/testimonials/devanshi-jain.jpg'),
    name: 'Devanshi Jain',
    info: 'Grade - 4 | Singapore',
  },
  {
    text:
      'My journey with Codingal started two weeks ago. I had seven classes, and my teacher is very nice & understanding. She even gives me assignments to understand the concept. I always want to keep learning coding with Codingal.',
    image: staticFile('/images/landing-page/testimonials/abdul-hannan.jpg'),
    name: 'Abdul Hannan',
    info: 'Grade - 3 | Nigeria',
  },
  {
    text:
      'I am delighted to see how much Dishita has learned over these few months with Codingal. I saw that my kid’s academic curriculum didn’t include coding. This felt like a massive gap between what skills are required and what is being taught in schools. So, I have decided to somehow make my daughter learn coding. I am very thrilled with the overall experience and improvement of my kid.',
    image: staticFile('/images/landing-page/testimonials/amit-karkare.jpg'),
    name: 'Amit Karkare',
    info: 'Father of Dishita | Bangalore',
  },
  {
    text:
      'Codingal is a proven solution for parents to engage their kids into a platform where they can start learning to code. My son developed logical thinking because of the use of logical blocks in programming. Problem-solving, designing, and mathematical skills have also significantly improved. My son’s experience with teachers has also been great.',
    image: staticFile('/images/landing-page/testimonials/meenal-santosh.jpg'),
    name: 'Meenal Santosh',
    info: '',
  },
  {
    text:
      'Codingal has helped my son to start learning coding at a good pace compared to other online sites. The teachers are friendly, and my son can understand the concepts easily.  In just three classes, he was able to write a basic program and make a few animations. I think Codingal can help him to grow and understand how to progress in this field, which is much needed in the ever-changing world.',
    image: staticFile('/images/landing-page/testimonials/samar-shareef.jpg'),
    name: 'Samar Shareef',
    info: 'Nigeria',
  },
]

export const IOI_STUDENT_TESTIMONIALS: CustomerTestimonialType[] = [
  {
    testimonial:
      'I always enjoy Codingal competitions as the increasing difficulty levels as we progress make it so much more exciting.',
    name: 'Mohit Hulse',
    type: 'Student',
    grade: 11,
    image: 'mohit-hulse',
  },
  {
    testimonial:
      'Codingal gave me the once-in-a-lifetime experience of seeing my code run on the International Space Station.',
    name: 'Siddhant',
    type: 'Student',
    grade: 10,
    image: 'siddhant',
  },
  {
    testimonial:
      'I won the HPE CodeWars competition and got the opportunity to run an experiment on the ISS thanks to Codingal.',
    name: 'Swastik',
    type: 'Student',
    grade: 12,
    image: 'swastik',
  },
  {
    testimonial:
      'As a student preparing for the IOI, Codingal has helped me greatly improve my problem solving and algorithmic thinking.',
    name: 'Sushil Raaja U',
    type: 'Student',
    grade: 11,
    image: 'sushil-raaja-u',
  },
  {
    testimonial:
      "The algorithmic problems in Codingal's competitions are super intriguing and I always get to learn something new.",
    name: 'Aarav Eeshan',
    type: 'Student',
    grade: 11,
    image: 'aarav-eeshan',
  },
]

export const TEACHER_TESTIMONIALS = [
  {
    name: 'Archana S Pandit',
    image: staticFile(
      '/images/landing-page/testimonials/archana-s-pandit.jpeg'
    ),
    text: `Codingal has given new wings to my career. I have never felt so
  motivated and empowered to teach. All my students enjoy
  learning it as they can put their ideas into animation. I am
  delighted to be a part of Codingal and continue to grow with them.`,
  },
  {
    name: 'Ruchi Vaishya',
    image: staticFile('/images/landing-page/testimonials/ruchi-vaishya.jpeg'),
    text: `
  I have enjoyed working as a teacher for Codingal and being part of
  this team so far. Fantastic work culture & supportive team have
  helped a lot in numerous ways in learning and bestowing the
  knowledge.`,
  },
  {
    name: 'Manasa S',
    image: staticFile('/images/landing-page/testimonials/manasa-s.jpeg'),
    text: `
  What I feel most happy about being a teacher at Codingal is that
  they always focus on kids' achievement. Being a teacher,
  I'm thankful to the Codingal team for their training,
  support, and encouragement.`,
  },
]

export const TRIAL_BOOKING_PAGE_TESTIMONIALS = [
  {
    text:
      'Codingal’s demo class helped me develop a keen interest in coding. So further, I decided to learn app and web development. Coding is now super fun for me, and it has improved my thinking and logical skills.',
    image: staticFile('/images/book-trial-class/user1.png'),
    name: 'Dishita Karkare',
    info: 'Grade 5 | India',
  },
  {
    text:
      'I am delighted to see how much Dishita has learned over these few months with Codingal. I saw that my kid’s academic curriculum didn’t include coding. This felt like a massive gap between what skills are required and what is being taught in schools. So, I have decided to somehow make my daughter learn coding. I am very thrilled with the overall experience and improvement of my kid.',
    image: staticFile('/images/book-trial-class/user2.png'),
    name: 'Amit Karkare',
    info: 'Parent | India',
  },
  {
    text:
      'Codingal is a proven solution for parents to engage their kids into a platform where they can start learning to code. My son developed logical thinking because of the use of logical blocks in programming. Problem-solving, designing, and mathematical skills have also significantly improved. My son’s experience with teachers has also been great.',
    image: staticFile('/images/book-trial-class/user3.png'),
    name: 'Meenal Santosh',
    info: 'Parent | India',
  },
]

export const MIDDLE_EAST_COUNTRY = [
  'AE',
  'SA',
  'QA',
  'OM',
  'BH',
  'EG',
  'LB',
  'JO',
  'KW',
  'TR',
]

export const CODING_PROJECT_TESTIMONIALS: CustomerTestimonialType<
  LinguiJSMessageDescriptor
>[] = [
  {
    testimonial: defineMessage({
      message:
        'The IIT Roorkee coding program was incredible! I learned by building real coding projects, and it made learning so much fun.',
      id:
        '[testimonial.testimonial]The IIT Roorkee coding program was incredible! I learned by building real coding projects, and it made learning so much fun.',
    }),
    name: defineMessage({
      message: 'Ojas Sharma',
      id: '[testimonial.name]OjasSharma',
    }),
    type: 'Student',
    grade: 5,
    image: 'ojas',
  },
  {
    testimonial: defineMessage({
      message:
        'I collaborated with peers & learned from expert teachers. The IIT Bombay coding competition boosted my creativity, critical thinking, and coding abilities.',
      id:
        '[testimonial.testimonial]I collaborated with peers & learned from expert teachers. The IIT Bombay coding competition boosted my creativity, critical thinking, and coding abilities.',
    }),
    name: defineMessage({
      message: 'Aarav S',
      id: '[testimonial.name]AaravS',
    }),
    type: 'Student',
    grade: 10,
    image: 'aarav',
  },
  {
    testimonial: defineMessage({
      message: `The IIT Guwahati Hackathon created a strong foundation for my daughter's coding journey. The collaborative learning environment was remarkable`,
      id:
        '[testimonial.testimonial]The IIT Guwahati Hackathon created a strong foundation for my daughters coding journey. The collaborative learning environment was remarkable',
    }),
    name: defineMessage({
      message: 'Priyanka',
      id: '[testimonial.name]Priyanka',
    }),
    type: 'Parent',
    image: 'priyanka',
  },
  {
    testimonial: defineMessage({
      message: `I found Codingal's competitions and projects engaging. I've built my own website and learned so much about how the internet works. I'm enjoying my classes`,
      id:
        '[testimonial.testimonial]I found Codingals competitions and projects engaging. Ive built my own website and learned so much about how the internet works. Im enjoying my classes',
    }),
    name: defineMessage({
      message: 'Riya',
      id: '[testimonial.name]Riya',
    }),
    type: 'Student',
    grade: 4,
    image: 'riya',
  },
]

export const COUNTRY_CUSTOMER_TESTIMONIALS: Record<
  string,
  CustomerTestimonialType<LinguiJSMessageDescriptor>[]
> = {
  PK: [
    {
      testimonial: defineMessage({
        message:
          'Codingal offers an enhanced code learning experience compared to free resources and helped me learn faster.',
        id:
          '[testimonial.testimonial]Codingal offers an enhanced code learning experience compared to free resources and helped me learn faster.',
      }),
      name: defineMessage({ message: 'Babar', id: '[testimonial.name]Babar' }),
      type: 'Student',
      grade: 5,
      image: 'pkstudent1',
    },
    {
      testimonial: defineMessage({
        message:
          'Codingal teachers know that each child is different and fully personalize the learning experience to suit the child.',
        id:
          '[testimonial.testimonial]Codingal teachers know that each child is different and fully personalize the learning experience to suit the child.',
      }),
      name: defineMessage({
        message: 'Faiza Khalid',
        id: '[testimonial.name]Faiza Khalid',
      }),
      type: 'Parent',
      image: 'pkparent1',
    },
    {
      testimonial: defineMessage({
        message:
          'The easy-to-grasp and detailed app development course by Codingal is the perfect go-to solution to learn to code. Thank you, Codingal.',
        id:
          '[testimonial.testimonial]The easy-to-grasp and detailed app development course by Codingal is the perfect go-to solution to learn to code. Thank you, Codingal.',
      }),
      name: defineMessage({
        message: 'M.Bilal Adil',
        id: '[testimonial.name]M.Bilal Adil',
      }),
      type: 'Student',
      grade: 4,
      image: 'student027',
    },
    {
      testimonial: defineMessage({
        message:
          'The curriculum at Codingal is thoughtful. The teachers are highly qualified and patient. The customer service is excellent and flexible scheduling. Highly recommended.',
        id:
          '[testimonial.testimonial]The curriculum at Codingal is thoughtful. The teachers are highly qualified and patient. The customer service is excellent and flexible scheduling. Highly recommended.',
      }),
      name: defineMessage({
        message: 'Adil Tanveer',
        id: '[testimonial.name]Adil Tanveer',
      }),
      type: 'Parent',
      image: 'parent027',
    },
    {
      testimonial: defineMessage({
        message: `Codingal's data science course has made me curious and involved in concepts of AI and Machine learning.`,
        id: `[testimonial.testimonial]Codingal's data science course has made me curious and involved in concepts of AI and Machine learning.`,
      }),
      name: defineMessage({
        message: 'Tassawar',
        id: '[testimonial.name]Tassawar',
      }),
      type: 'Student',
      grade: 12,
      image: 'Tassawar',
    },
    {
      testimonial: defineMessage({
        message:
          'Codingal has made my child excited to learn data science in depth.',
        id:
          '[testimonial.testimonial]Codingal has made my child excited to learn data science in depth.',
      }),
      name: defineMessage({
        message: 'Razzaq Ahmed',
        id: '[testimonial.name]Razzaq Ahmed',
      }),
      type: 'Parent',
      image: 'p-Razzaq',
    },
  ],
  NP: [
    {
      testimonial: defineMessage({
        message:
          'Thanks to Codingal, I have learnt to code with utmost accuracy and in a very engaging manner.',
        id:
          '[testimonial.testimonial]Thanks to Codingal, I have learnt to code with utmost accuracy and in a very engaging manner.',
      }),
      name: defineMessage({
        message: 'Shreyansh',
        id: '[testimonial.name]Shreyansh',
      }),
      type: 'Student',
      grade: 7,
      image: 'shreyansh',
    },
    {
      testimonial: defineMessage({
        message:
          'Thanks to Codingal, now I have a complete understanding of App Inventor and Block-based Programming. The App development course has helped me develop my games.',
        id:
          '[testimonial.testimonial]Thanks to Codingal, now I have a complete understanding of App Inventor and Block-based Programming. The App development course has helped me develop my games.',
      }),
      name: defineMessage({
        message: 'Aaditya',
        id: '[testimonial.name]Aaditya',
      }),
      type: 'Student',
      grade: 12,
      image: 'student030',
    },
    {
      testimonial: defineMessage({
        message:
          'I am more than happy that my son can develop an app and create stories. Thank you Codingal for making my child learn to code interactively.',
        id:
          '[testimonial.testimonial]I am more than happy that my son can develop an app and create stories. Thank you Codingal for making my child learn to code interactively.',
      }),
      name: defineMessage({
        message: 'Jagat Raj',
        id: '[testimonial.name]Jagat Raj',
      }),
      type: 'Parent',
      image: 'parent030',
    },
  ],
  ID: [
    {
      testimonial: defineMessage({
        message:
          'The coding classes are highly interactive and the projects I worked on also helped me perform better at school.',
        id:
          '[testimonial.testimonial]The coding classes are highly interactive and the projects I worked on also helped me perform better at school.',
      }),
      name: defineMessage({
        message: 'Rivaan',
        id: '[testimonial.name]Rivaan',
      }),
      type: 'Student',
      grade: 1,
      image: 'rivaan',
    },
    {
      testimonial: defineMessage({
        message:
          'My child is so happy to have joined Codingal and really loves to be part of a thriving community of coders.',
        id:
          '[testimonial.testimonial]My child is so happy to have joined Codingal and really loves to be part of a thriving community of coders.',
      }),
      name: defineMessage({
        message: 'Debashis',
        id: '[testimonial.name]Debashis',
      }),
      type: 'Parent',
      image: 'p-debashis',
    },
    {
      testimonial: defineMessage({
        message:
          'Excellent classes with complete module and dashboard. It is fun learning to code with real tools and languages.',
        id:
          '[testimonial.testimonial]Excellent classes with complete module and dashboard. It is fun learning to code with real tools and languages.',
      }),
      name: defineMessage({ message: 'Azka', id: '[testimonial.name]Azka' }),
      type: 'Student',
      grade: 1,
      image: 'azka',
    },
    {
      testimonial: defineMessage({
        message:
          'Although we are not very fluent in English, the Teacher patiently explain step by step and in interactive way.',
        id:
          '[testimonial.testimonial]Although we are not very fluent in English, the Teacher patiently explain step by step and in interactive way.',
      }),
      name: defineMessage({ message: 'Rossa', id: '[testimonial.name]Rossa' }),
      type: 'Parent',
      image: 'p-rossa',
    },
  ],
  BD: [
    {
      testimonial: defineMessage({
        message:
          'The teachers at Codingal are very knowledgeable, warm and supportive. I am happy learning to code under their guidance.',
        id:
          '[testimonial.testimonial]The teachers at Codingal are very knowledgeable, warm and supportive. I am happy learning to code under their guidance.',
      }),
      name: defineMessage({
        message: 'Hadiqah',
        id: '[testimonial.name]Hadiqah',
      }),
      type: 'Student',
      grade: 1,
      image: 'student021',
    },
    {
      testimonial: defineMessage({
        message:
          'The customisable courses at Codingal give my child a real advantage: academically, socially, and in technology. Highly recommended!',
        id:
          '[testimonial.testimonial]The customisable courses at Codingal give my child a real advantage: academically, socially, and in technology. Highly recommended!',
      }),
      name: defineMessage({
        message: 'Hafsa Lodhi',
        id: '[testimonial.name]Hafsa Lodhi',
      }),
      type: 'Parent',
      image: 'parent021',
    },
  ],
  KE: [
    {
      testimonial: defineMessage({
        message: `My teacher at Codingal was the best guide I could've asked for as they really customized the classes to match my learning style.`,
        id: `[testimonial.testimonial]My teacher at Codingal was the best guide I could've asked for as they really customized the classes to match my learning style.`,
      }),
      name: defineMessage({
        message: 'Melvin Ruto',
        id: '[testimonial.name]Melvin Ruto',
      }),
      type: 'Student',
      grade: 6,
      image: 'student020',
    },
    {
      testimonial: defineMessage({
        message:
          'Codingal provides ultimate scheduling flexibility and customized curriculum with experienced teachers that helps build creativity in my child',
        id:
          '[testimonial.testimonial]Codingal provides ultimate scheduling flexibility and customized curriculum with experienced teachers that helps build creativity in my child',
      }),
      name: defineMessage({
        message: 'Eva Meshack',
        id: '[testimonial.name]Eva Meshack',
      }),
      type: 'Parent',
      image: 'parent020',
    },
  ],
  MIDDLE_EAST: [
    {
      testimonial: defineMessage({
        message:
          'Codingal personalized the course for me and allowed me to learn coding at my own pace from the comfort of home.',
        id:
          '[testimonial.testimonial]Codingal personalized the course for me and allowed me to learn coding at my own pace from the comfort of home.',
      }),
      name: defineMessage({
        message: 'Gladys',
        id: '[testimonial.name]Gladys',
      }),
      type: 'Student',
      grade: 8,
      image: 'gladys',
    },
    {
      testimonial: defineMessage({
        message:
          'Teachers at Codingal are truly top quality as they got my child to fall in love with coding and created the desire to excel.',
        id:
          '[testimonial.testimonial]Teachers at Codingal are truly top quality as they got my child to fall in love with coding and created the desire to excel.',
      }),
      name: defineMessage({
        message: 'Prapulla',
        id: '[testimonial.name]Prapulla',
      }),
      type: 'Parent',
      image: 'parent015',
    },
    {
      testimonial: defineMessage({
        message:
          'The best thing about learning coding with Codingal is the teachers who always went the extra mile for me.',
        id:
          '[testimonial.testimonial]The best thing about learning coding with Codingal is the teachers who always went the extra mile for me.',
      }),
      name: defineMessage({
        message: 'Muhammed Umer',
        id: '[testimonial.name]Muhammed Umer',
      }),
      type: 'Student',
      grade: 1,
      image: 'student017',
    },
    {
      testimonial: defineMessage({
        message:
          'Codingal not only taught my child to code but also gave them the creative, analytical, and entrepreneurial skills they need to be successful in the future.',
        id:
          '[testimonial.testimonial]Codingal not only taught my child to code but also gave them the creative, analytical, and entrepreneurial skills they need to be successful in the future.',
      }),
      name: defineMessage({
        message: 'Harsha',
        id: '[testimonial.name]Harsha',
      }),
      type: 'Parent',
      image: 'parent017',
    },
    {
      testimonial: defineMessage({
        message:
          'Learning to code has boosted my self-confidence and brought me closer to my aspirations of joining a big technology company.',
        id:
          '[testimonial.testimonial]Learning to code has boosted my self-confidence and brought me closer to my aspirations of joining a big technology company.',
      }),
      name: defineMessage({
        message: 'Muhammed',
        id: '[testimonial.name]Muhammed',
      }),
      type: 'Student',
      grade: 1,
      image: 'student016',
    },
    {
      testimonial: defineMessage({
        message:
          'Codingal actually makes coding so much fun that my child now spends her free time creating things with his new skills.',
        id:
          '[testimonial.testimonial]Codingal actually makes coding so much fun that my child now spends her free time creating things with his new skills.',
      }),
      name: defineMessage({
        message: 'Rubiya',
        id: '[testimonial.name]Rubiya',
      }),
      type: 'Parent',
      image: 'parent016',
    },
  ],
  DEFAULT: [
    {
      testimonial: defineMessage({
        message: `I love learning with Codingal. It's always fun and the teacher is nice and kind.`,
        id: `[testimonial.testimonial]I love learning with Codingal. It's always fun and the teacher is nice and kind.`,
      }),
      name: defineMessage({ message: 'Ray', id: '[testimonial.name]Ray' }),
      type: 'Student',
      grade: 3,
      image: 'ray',
    },
    {
      testimonial: defineMessage({
        message:
          'The teachers at Codingal are very knowledgeable, warm and supportive. I am happy learning to code under their guidance.',
        id:
          '[testimonial.testimonial]The teachers at Codingal are very knowledgeable, warm and supportive. I am happy learning to code under their guidance.',
      }),
      name: defineMessage({
        message: 'Hadiqah',
        id: '[testimonial.name]Hadiqah',
      }),
      type: 'Student',
      grade: 1,
      image: 'hadiqah',
    },
    {
      testimonial: defineMessage({
        message:
          'My sister and I both take classes on Codingal and we love to try out what we learned together after class.',
        id:
          '[testimonial.testimonial]My sister and I both take classes on Codingal and we love to try out what we learned together after class.',
      }),
      name: defineMessage({ message: 'Arnav', id: '[testimonial.name]Arnav' }),
      type: 'Student',
      grade: 3,
      image: 'arnav',
    },
    {
      testimonial: defineMessage({
        message: `Codingal makes learning to code very easy. I've won two coding competitions since I joined.`,
        id: `[testimonial.testimonial]Codingal makes learning to code very easy. I've won two coding competitions since I joined.`,
      }),
      name: defineMessage({
        message: 'Samhith',
        id: '[testimonial.name]Samhith',
      }),
      type: 'Student',
      grade: 2,
      image: 'samhith',
    },
    {
      testimonial: defineMessage({
        message: `I'm glad I picked up coding at an early age as it has improved my logical thinking and sparked my creativity.`,
        id: `[testimonial.testimonial]I'm glad I picked up coding at an early age as it has improved my logical thinking and sparked my creativity.`,
      }),
      name: defineMessage({ message: 'Ira', id: '[testimonial.name]Ira' }),
      type: 'Student',
      grade: 2,
      image: 'ira',
    },
    {
      testimonial: defineMessage({
        message:
          'My teacher always goes the extra mile and explains concepts in a manner that I can understand.',
        id:
          '[testimonial.testimonial]My teacher always goes the extra mile and explains concepts in a manner that I can understand.',
      }),
      name: defineMessage({
        message: 'Advitiya',
        id: '[testimonial.name]Advitiya',
      }),
      type: 'Student',
      grade: 2,
      image: 'advitiya',
    },
    {
      testimonial: defineMessage({
        message:
          'My teacher explains each concept clearly and also takes doubt sessions if I want to learn something again.',
        id:
          '[testimonial.testimonial]My teacher explains each concept clearly and also takes doubt sessions if I want to learn something again.',
      }),
      name: defineMessage({
        message: 'Shraddha',
        id: '[testimonial.name]Shraddha',
      }),
      type: 'Student',
      grade: 4,
      image: 'shraddha',
    },
    {
      testimonial: defineMessage({
        message: `I'm glad to be learning coding with Codingal. I now have two apps published on the Google Play Store.`,
        id: `[testimonial.testimonial]I'm glad to be learning coding with Codingal. I now have two apps published on the Google Play Store.`,
      }),
      name: defineMessage({
        message: 'Saaransh',
        id: '[testimonial.name]Saaransh',
      }),
      type: 'Student',
      grade: 4,
      image: 'saaransh',
    },
    {
      testimonial: defineMessage({
        message:
          'I love the fact that Codingal customizes the courses based on the interests of the student.',
        id:
          '[testimonial.testimonial]I love the fact that Codingal customizes the courses based on the interests of the student.',
      }),
      name: defineMessage({
        message: 'Aditya',
        id: '[testimonial.name]Aditya',
      }),
      type: 'Student',
      grade: 6,
      image: 'aditya',
    },
    {
      testimonial: defineMessage({
        message:
          'The classes are lively and interactive, and the teacher ensured a comfortable learning experience.',
        id:
          '[testimonial.testimonial]The classes are lively and interactive, and the teacher ensured a comfortable learning experience.',
      }),
      name: defineMessage({
        message: 'Saagar',
        id: '[testimonial.name]Saagar',
      }),
      type: 'Parent',
      image: 'p-saagar',
    },
    {
      testimonial: defineMessage({
        message: `Codingal's well-structured courses have made coding fun for my son. They're the best and the quickest.`,
        id: `[testimonial.testimonial]Codingal's well-structured courses have made coding fun for my son. They're the best and the quickest.`,
      }),
      name: defineMessage({ message: 'Ikumi', id: '[testimonial.name]Ikumi' }),
      type: 'Parent',
      image: 'p-ikumi',
    },
    {
      testimonial: defineMessage({
        message: `Codingal builds a strong foundation and has helped enhance my daughter's confidence and creativity.`,
        id: `[testimonial.testimonial]Codingal builds a strong foundation and has helped enhance my daughter's confidence and creativity.`,
      }),
      name: defineMessage({ message: 'Roopa', id: '[testimonial.name]Roopa' }),
      type: 'Parent',
      image: 'p-roopa',
    },
    {
      testimonial: defineMessage({
        message:
          'The courses develop multiple skills and ensure maximum clarity of coding concepts.',
        id:
          '[testimonial.testimonial]The courses develop multiple skills and ensure maximum clarity of coding concepts.',
      }),
      name: defineMessage({
        message: 'Lavanya',
        id: '[testimonial.name]Lavanya',
      }),
      type: 'Parent',
      image: 'p-lavanya',
    },
    {
      testimonial: defineMessage({
        message:
          'My son is now an expert in web development, all thanks to the excellent teachers on Codingal.',
        id:
          '[testimonial.testimonial]My son is now an expert in web development, all thanks to the excellent teachers on Codingal.',
      }),
      name: defineMessage({ message: 'Ashok', id: '[testimonial.name]Ashok' }),
      type: 'Parent',
      image: 'p-ashok',
    },
    {
      testimonial: defineMessage({
        message: `The teacher matched my child's pace of learning and made the learning process more enjoyable.`,
        id: `[testimonial.testimonial]The teacher matched my child's pace of learning and made the learning process more enjoyable.`,
      }),
      name: defineMessage({
        message: 'Bhargavi',
        id: '[testimonial.name]Bhargavi',
      }),
      type: 'Parent',
      image: 'p-bhargavi',
    },
    {
      testimonial: defineMessage({
        message: `The Codingal instructor taught the App development course fabulously. I had a lot of fun creating games and apps. I want to thank my instructor, and I'm delighted I did this course.`,
        id: `[testimonial.testimonial]The Codingal instructor taught the App development course fabulously. I had a lot of fun creating games and apps. I want to thank my instructor, and I'm delighted I did this course.`,
      }),
      name: defineMessage({ message: 'Kian', id: '[testimonial.name]Kian' }),
      type: 'Student',
      grade: 2,
      image: 'kian',
    },
    {
      testimonial: defineMessage({
        message: `Codingal classes are so much fun. I've started to really enjoy creating things with code.`,
        id: `[testimonial.testimonial]Codingal classes are so much fun. I've started to really enjoy creating things with code.`,
      }),
      name: defineMessage({
        message: 'Billie',
        id: '[testimonial.name]Billie',
      }),
      type: 'Student',
      grade: 5,
      image: 'billie',
    },
    {
      testimonial: defineMessage({
        message: `I recently joined Codingal's Game development course, and I look forward to attending my classes. I love learning so many new things like code, developing games, and much more.`,
        id: `[testimonial.testimonial]I recently joined Codingal's Game development course, and I look forward to attending my classes. I love learning so many new things like code, developing games, and much more.`,
      }),
      name: defineMessage({
        message: 'Sheikh Adheen',
        id: '[testimonial.name]Sheikh Adheen',
      }),
      type: 'Student',
      grade: 7,
      image: 'student032',
    },

    {
      testimonial: defineMessage({
        message: `My child is learning the fundamentals of coding with the guidance of a highly experienced and qualified Codingal instructor.`,
        id: `[testimonial.testimonial]My child is learning the fundamentals of coding with the guidance of a highly experienced and qualified Codingal instructor.`,
      }),
      name: defineMessage({
        message: 'Irtiza Muzamil',
        id: '[testimonial.name]Irtiza Muzamil',
      }),
      type: 'Parent',
      image: 'parent032',
    },
    {
      testimonial: defineMessage({
        message: `The Game development course is the perfect course for my interest. The curriculum is thoughtfully designed so that even a beginner like me can grasp the subject well.`,
        id: `[testimonial.testimonial]The Game development course is the perfect course for my interest. The curriculum is thoughtfully designed so that even a beginner like me can grasp the subject well.`,
      }),
      name: defineMessage({
        message: 'Vanishka',
        id: '[testimonial.name]Vanishka',
      }),
      type: 'Student',
      grade: 6,
      image: 'Vanshika',
    },

    {
      testimonial: defineMessage({
        message: `The Game development course is incredibly effective. My daughter is already applying what she has learned at Codingal in other subjects at school.`,
        id: `[testimonial.testimonial]The Game development course is incredibly effective. My daughter is already applying what she has learned at Codingal in other subjects at school.`,
      }),
      name: defineMessage({
        message: 'Sanjay Kumar',
        id: '[testimonial.name]Sanjay Kumar',
      }),
      type: 'Parent',
      image: 'p-Sanjay',
    },
    {
      testimonial: defineMessage({
        message:
          'I always wait excitedly to attend my Codingal class after school. I enjoy learning data science a lot.',
        id:
          '[testimonial.testimonial]I always wait excitedly to attend my Codingal class after school. I enjoy learning data science a lot.',
      }),
      name: defineMessage({
        message: 'Nishika',
        id: '[testimonial.name]Nishika',
      }),
      type: 'Student',
      grade: 12,
      image: 'Nishika',
    },

    {
      testimonial: defineMessage({
        message:
          'My child eagerly waits to learn from her coding teacher who makes learning interactive and fun.',
        id:
          '[testimonial.testimonial]My child eagerly waits to learn from her coding teacher who makes learning interactive and fun.',
      }),
      name: defineMessage({
        message: 'Urvashi',
        id: '[testimonial.name]Urvashi',
      }),
      type: 'Parent',
      image: 'p-Urvashi',
    },
  ],
}
