import { IconProps } from '@components/common/icons'
import useIsIndia from '@hooks/useIsIndia'
import { Trans } from '@lingui/macro'
import { Heart } from 'react-feather'

type BuiltInIndiaProps = Omit<IconProps, 'name'> & {
  className?: any
}

/** i18n support added */
const BuiltInIndia = ({
  className = 'inline w-4 h-4 text-red-200 fill-current',
  ...props
}: BuiltInIndiaProps) => {
  const { isIndia } = useIsIndia()

  if (!isIndia) return null
  return (
    <span>
      <Trans id="trialClass.builtWithLove">
        Built with <Heart className={className} {...props} /> in India
      </Trans>
    </span>
  )
}

export default BuiltInIndia
