import styles from './dotted-card-bg.module.sass'

export const DottedCardBackgroundWrapper = (WrappedComponent) => {
  return function (props) {
    return (
      <div className={styles.dottedCardWrapper}>
        <WrappedComponent {...props} />
      </div>
    )
  }
}

export default DottedCardBackgroundWrapper
