import { TimeType } from '@hooks/useCountdown'
import { captureException } from '@sentry/node'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import durationPlugin from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'
dayjs.extend(isBetween)
dayjs.extend(durationPlugin)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(utcPlugin)
dayjs.extend(timezonePlugin)
import { parseISO, nextDay, addMinutes } from 'date-fns'

const date = dayjs

export const currentYear = date().format('YYYY')

export const TIME_FORMAT_TZ = 'hh:mm A [Z], MMM DD, YYYY'
export const TIME_FORMAT_TZ_ONLY_TIME = 'hh:mm A [Z]'
export const TIME_FORMAT_TZ_ONLY_DATE = 'ddd, MMM DD'

export const SYSTEM_TIMEZONE = Intl?.DateTimeFormat()?.resolvedOptions()
  ?.timeZone
/**
 * @param timezone
 * @returns the abbreiviated time zone of the user's current region
 */
const getCurrentAbbreviatedTimeZone = (timezone: string) => {
  const backendAlignedTimezoneAbbreviations = {
    'Pacific/Fiji': 'FJT', // Fiji Time
    // Add more known timezones here
  }

  if (backendAlignedTimezoneAbbreviations[timezone]) {
    return backendAlignedTimezoneAbbreviations[timezone]
  }

  const timeZoneName = new Date().toLocaleDateString('en-US', {
    timeZoneName: 'long',
    timeZone: timezone,
  })
  try {
    const timeZoneAbbr = timeZoneName
      .split(',')[1]
      .trim()
      .match(/\b(\w)/g)
      .join('')
    return timeZoneAbbr
  } catch (error) {
    captureException(error)
    console.error(error)
    return 'Local Time'
  }
}

export const formatTimeByTimezone = (
  dateToFormat: string | Date,
  timezone: string,
  format: string
) => {
  const abbreviatedTimeZone = getCurrentAbbreviatedTimeZone(timezone)
  const updatedFormat = format.replace('Z', abbreviatedTimeZone)
  return date(dateToFormat).tz(timezone).format(updatedFormat)
}

/**
 *
 * @param milliseconds The ms to convert to date object
 * @returns date objects with keys days, hours, minutes, seconds
 */
export function millisecondsToTime(
  milliseconds: number
): Omit<TimeType, 'oneTenthMs'> {
  const days = Math.floor(milliseconds / (24 * 60 * 60 * 1000))
  const daysMs = milliseconds % (24 * 60 * 60 * 1000)
  const hours = Math.floor(daysMs / (60 * 60 * 1000))
  const hoursMs = milliseconds % (60 * 60 * 1000)
  const minutes = Math.floor(hoursMs / (60 * 1000))
  const minutesMs = milliseconds % (60 * 1000)
  const sec = Math.floor(minutesMs / 1000)
  return { day: days, hour: hours, min: minutes, sec }
}

/**
 * @param milliseconds The ms to convert to date object
 * @returns date objects with keys days, hours, minutes, seconds, oneTenthMs
 */
export function millisecondsToTimeType(milliseconds: number): TimeType {
  const days = Math.floor(milliseconds / (24 * 60 * 60 * 1000))
  const daysMs = milliseconds % (24 * 60 * 60 * 1000)
  const hours = Math.floor(daysMs / (60 * 60 * 1000))
  const hoursMs = milliseconds % (60 * 60 * 1000)
  const minutes = Math.floor(hoursMs / (60 * 1000))
  const minutesMs = milliseconds % (60 * 1000)
  const sec = Math.floor(minutesMs / 1000)
  const oneTenthMs = Math.floor(milliseconds % 1000)
  return { day: days, hour: hours, min: minutes, sec, oneTenthMs }
}

export const getNextClassDate = (startDate: string, slots: object) => {
  // This function is written in such a way that while getting the next Class date, there is
  // no affect of the system timezone. It returns the next class date in IST timezone.
  const week_day_to_label_map = {
    sun: 0,
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
  }
  // 5 hours 30 minutes in minutes
  const IST_OFFSET = 330
  // Parse the startDate as UTC so that system tz won't affect it:
  const baseDateUTC = parseISO(startDate)
  // Convert to "India local time" by adding +5:30
  const baseDateInIndia = addMinutes(baseDateUTC, IST_OFFSET)

  const nextClassDates = []

  Object.keys(slots).forEach((day) => {
    const dayIndex = week_day_to_label_map[day]
    const nextClassDateInIndia = nextDay(baseDateInIndia, dayIndex)
    const [hours, minutes] = slots[day].split(':').map(Number)

    // Get the final date of the next class, and parse it in IST timezone
    // and add the slot hours and minutes to it, as the slots are in IST timezone.
    const finalNextClassDate = date
      .tz(
        nextClassDateInIndia.toLocaleDateString('en-GB'),
        'DD-MM-YYYY',
        'Asia/Kolkata'
      )
      .set('hour', hours)
      .set('minute', minutes)
    nextClassDates.push(finalNextClassDate.toISOString())
  })
  // Sort the dates in descending order (latest first)
  nextClassDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
  return nextClassDates[0]
}

export default date
